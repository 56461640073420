import React from 'react';

const TrainerProfile = () => {

    
    return (
        <div>
            <p>트레이너 페이지</p>
        </div>
    );
};

export default TrainerProfile;