// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MainPage.css */
.MainPage {
    text-align: center;
    padding: 20px;
    background-color: #f0f8ff;
  }
  
  .MainPage-header {
    background-color: #00bfff;
    padding: 20px;
    border-radius: 10px;
    color: white;
    margin-bottom: 20px;
  }
  
  .game-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  `, "",{"version":3,"sources":["webpack://./src/MainPage.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,kBAAkB;IAClB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,eAAe;EACjB","sourcesContent":["/* MainPage.css */\r\n.MainPage {\r\n    text-align: center;\r\n    padding: 20px;\r\n    background-color: #f0f8ff;\r\n  }\r\n  \r\n  .MainPage-header {\r\n    background-color: #00bfff;\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    color: white;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .game-grid {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    flex-wrap: wrap;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
