// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.banner_sliderContainer__J0Vr7 {
  position: relative;
  overflow: hidden;
  width: 80%;
  max-width: 100%; /* 최대 너비 설정 */
  height: auto; /* 높이를 자동으로 조절하여 이미지 비율 유지 */
}

.banner_sliderWrapper__8qU-x {
  position: relative;
  display: flex;
  transition: transform 0.5s ease-in-out;
  z-index: -50;
}

.banner_sliderImage__sninA {
  max-width: 100%; /* 최대 너비 설정 */
  height: auto; /* 이미지 비율 유지 */
}

.banner_prevBtn__SRG2H,
.banner_nextBtn__IKmHj {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.banner_prevBtn__SRG2H {
  left: 10px;
}

.banner_nextBtn__IKmHj {
  right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/style/banner.module.css"],"names":[],"mappings":";;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,eAAe,EAAE,aAAa;EAC9B,YAAY,EAAE,4BAA4B;AAC5C;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sCAAsC;EACtC,YAAY;AACd;;AAEA;EACE,eAAe,EAAE,aAAa;EAC9B,YAAY,EAAE,cAAc;AAC9B;;AAEA;;EAEE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb","sourcesContent":["\n\n.sliderContainer {\n  position: relative;\n  overflow: hidden;\n  width: 80%;\n  max-width: 100%; /* 최대 너비 설정 */\n  height: auto; /* 높이를 자동으로 조절하여 이미지 비율 유지 */\n}\n\n.sliderWrapper {\n  position: relative;\n  display: flex;\n  transition: transform 0.5s ease-in-out;\n  z-index: -50;\n}\n\n.sliderImage {\n  max-width: 100%; /* 최대 너비 설정 */\n  height: auto; /* 이미지 비율 유지 */\n}\n\n.prevBtn,\n.nextBtn {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  background-color: transparent;\n  border: none;\n  color: white;\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.prevBtn {\n  left: 10px;\n}\n\n.nextBtn {\n  right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderContainer": `banner_sliderContainer__J0Vr7`,
	"sliderWrapper": `banner_sliderWrapper__8qU-x`,
	"sliderImage": `banner_sliderImage__sninA`,
	"prevBtn": `banner_prevBtn__SRG2H`,
	"nextBtn": `banner_nextBtn__IKmHj`
};
export default ___CSS_LOADER_EXPORT___;
