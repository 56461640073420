// MainPage.js
import React from "react";
import GameCard from "./GameCard";
import './MainPage.css';

function MainPage() {
  const games = [
    { id: 1, name: "Memory Match", description: "카드를 뒤집어 같은 그림 찾기!", imageUrl: "/images/memory-match.png" },
    { id: 2, name: "피아노 타일", description: "빠르게 피아노 타일을 클릭해보세요!", imageUrl: "/images/piano-tiles.png" },
    { id: 3, name: "색깔 맞추기", description: "색깔을 맞춰보세요!", imageUrl: "/images/color-match.png" },
  ];

  return (
    <div className="MainPage">
      <header className="MainPage-header">
        <h1>서우 ,다솔 ,다예 ,다율 게임 사이트</h1>
        <p>재미있는 게임을 즐겨보세요!</p>
      </header>
      <div className="game-grid">
        {games.map(game => (
          <GameCard key={game.id} game={game} />
        ))}
      </div>
    </div>
  );
}

export default MainPage;
