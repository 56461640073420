// GameCard.js
import React from "react";
import './GameCard.css';

function GameCard({ game }) {
  return (
    <div className="game-card">
      <img src={game.imageUrl} alt={game.name} />
      <h2>{game.name}</h2>
      <p>{game.description}</p>
      <button onClick={() => alert(`${game.name} 시작!`)}>게임 시작</button>
    </div>
  );
}

export default GameCard;
