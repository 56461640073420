// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_modalNoticeContainer__XLCQW{
    border: 1px solid black;
    width: 360px;
    height: 400px;
    z-index: 150;
    top: 260px;
    right: -90px;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: white;
    justify-content: center;
    overflow: auto;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.modal_modalNoticeDetailContainer__\\+\\+Uax{
    border: 1px solid black;
    width: 320px;
    height: 100px;
    z-index: 150;
    top: 340px;
    right: -140px;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: white;
    justify-content: center;
    overflow: auto;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}
.modal_modalAdressContainer__keYqs{
    border: 1px solid black;
    width: 360px;
    height: 500px;
    z-index: 150;
    top: 560px;
    right: 90px;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: white;
    justify-content: center;
    overflow: auto;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}
.modal_closeBtn__zUHlM{
    cursor: pointer;
    background-color: transparent;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/style/modal.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,gCAAgC;IAChC,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,cAAc;IACd,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,UAAU;IACV,aAAa;IACb,kBAAkB;IAClB,gCAAgC;IAChC,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,cAAc;IACd,kCAA0B;YAA1B,0BAA0B;AAC9B;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,gCAAgC;IAChC,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,cAAc;IACd,kCAA0B;YAA1B,0BAA0B;AAC9B;AACA;IACI,eAAe;IACf,6BAA6B;IAC7B,YAAY;AAChB","sourcesContent":[".modalNoticeContainer{\n    border: 1px solid black;\n    width: 360px;\n    height: 400px;\n    z-index: 150;\n    top: 260px;\n    right: -90px;\n    position: absolute;\n    transform: translate(-50%, -50%);\n    border-radius: 10px;\n    background-color: white;\n    justify-content: center;\n    overflow: auto;\n    backdrop-filter: blur(5px);\n}\n\n.modalNoticeDetailContainer{\n    border: 1px solid black;\n    width: 320px;\n    height: 100px;\n    z-index: 150;\n    top: 340px;\n    right: -140px;\n    position: absolute;\n    transform: translate(-50%, -50%);\n    border-radius: 10px;\n    background-color: white;\n    justify-content: center;\n    overflow: auto;\n    backdrop-filter: blur(5px);\n}\n.modalAdressContainer{\n    border: 1px solid black;\n    width: 360px;\n    height: 500px;\n    z-index: 150;\n    top: 560px;\n    right: 90px;\n    position: absolute;\n    transform: translate(-50%, -50%);\n    border-radius: 10px;\n    background-color: white;\n    justify-content: center;\n    overflow: auto;\n    backdrop-filter: blur(5px);\n}\n.closeBtn{\n    cursor: pointer;\n    background-color: transparent;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalNoticeContainer": `modal_modalNoticeContainer__XLCQW`,
	"modalNoticeDetailContainer": `modal_modalNoticeDetailContainer__++Uax`,
	"modalAdressContainer": `modal_modalAdressContainer__keYqs`,
	"closeBtn": `modal_closeBtn__zUHlM`
};
export default ___CSS_LOADER_EXPORT___;
