// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* GameCard.css */
.game-card {
    background-color: #fffacd;
    border: 2px solid #ffd700;
    border-radius: 10px;
    width: 200px;
    padding: 15px;
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .game-card img {
    width: 100px;
    height: 100px;
  }
  
  .game-card h2 {
    color: #ff6347;
  }
  
  .game-card button {
    padding: 10px;
    background-color: #32cd32;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .game-card button:hover {
    background-color: #228b22;
  }
  `, "",{"version":3,"sources":["webpack://./src/GameCard.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* GameCard.css */\r\n.game-card {\r\n    background-color: #fffacd;\r\n    border: 2px solid #ffd700;\r\n    border-radius: 10px;\r\n    width: 200px;\r\n    padding: 15px;\r\n    margin: 15px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    text-align: center;\r\n  }\r\n  \r\n  .game-card img {\r\n    width: 100px;\r\n    height: 100px;\r\n  }\r\n  \r\n  .game-card h2 {\r\n    color: #ff6347;\r\n  }\r\n  \r\n  .game-card button {\r\n    padding: 10px;\r\n    background-color: #32cd32;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .game-card button:hover {\r\n    background-color: #228b22;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
