// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datePicker_time__6wkBY{
    background-color: transparent;
    border: none;
    margin-left: 5rem;
    font-size: 1.1rem;

}

.datePicker_timeBtn__WDl3-{
    background-color: transparent;
    border: none;
    margin-left: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
}

  `, "",{"version":3,"sources":["webpack://./src/style/datePicker.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;;AAErB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".time{\n    background-color: transparent;\n    border: none;\n    margin-left: 5rem;\n    font-size: 1.1rem;\n\n}\n\n.timeBtn{\n    background-color: transparent;\n    border: none;\n    margin-left: 5rem;\n    font-size: 1.1rem;\n    cursor: pointer;\n}\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"time": `datePicker_time__6wkBY`,
	"timeBtn": `datePicker_timeBtn__WDl3-`
};
export default ___CSS_LOADER_EXPORT___;
